import { AsyncResult, DataServiceQuery, IContext, IDataServiceRequest, callActionOrExecute } from '@msdyn365-commerce/retail-proxy';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

export function createCreateCountingListInput(
    fromDate: Date | undefined,
    toDate: Date | undefined,
    productListIds: string[],
    productList: Entities.ProductList
): IDataServiceRequest {
    const query = new DataServiceQuery('VinhusetProductLists', 'VinhusetProductList');
    return query.createDataServiceRequestForOperation(
        'CreateCountingList',
        true,
        EntityClasses.ProductListClass,
        'false',
        { bypassCache: 'get', returnEntity: 'Entities.ProductList' },
        { fromDate: fromDate || null, toDate: toDate || null, productListIds: productListIds, productList: productList }
    );
}

export function createCountingListAsync(
    context: IContext,
    fromDate: Date | undefined,
    toDate: Date | undefined,
    productListIds: string[],
    productList: Entities.ProductList
): AsyncResult<Entities.ProductList> {
    const request = createCreateCountingListInput(fromDate, toDate, productListIds, productList);
    return callActionOrExecute<Entities.ProductList>(request, context.callerContext);
}
