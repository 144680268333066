import {
    IActionContext,
    createObservableDataAction,
    IAction,
    ICommerceApiSettings,
    IGeneric,
    IAny,
    ICreateActionContext
} from "@msdyn365-commerce/core";
import {
    CacheType,
    callActionOrExecute,
    DataServiceQuery,
    IActionInput, IDataServiceRequest
} from '@msdyn365-commerce/retail-proxy';
import { ISpecialDeliveryModes, SpecialDeliveryModesExtensionClass } from './entities/special-delivery-modes';

export class GetSpecialDeliveryModesInput implements IActionInput {
    private readonly query: DataServiceQuery;

    constructor(apiSettings: ICommerceApiSettings) {
        this.query = new DataServiceQuery();
    }

    public getDataServiceRequest = (): IDataServiceRequest => {
        return this.query.createDataServiceRequestForOperation(
            'GetSpecialDeliveryModes',
            false,
            SpecialDeliveryModesExtensionClass,
            'false',
            { bypassCache: 'get', returnEntity: 'ISpecialDeliveryModes' },
            {}
        );
    };

    public shouldCacheOutput = () => false;
    public getCacheKey = () => 'SpecialDeliveryModes';
    public getCacheObjectType = () => 'SpecialDeliveryModes';
    public dataCacheType = (): CacheType => 'request';
}

function createInput(inputData: ICreateActionContext<IGeneric<IAny>>): GetSpecialDeliveryModesInput {
    return new GetSpecialDeliveryModesInput(inputData.requestContext.apiSettings);
}

export function getSpecialDeliveryModesAsync(input: GetSpecialDeliveryModesInput, context: IActionContext): Promise<ISpecialDeliveryModes> {
    return callActionOrExecute<ISpecialDeliveryModes>(input.getDataServiceRequest(), context);
}

export const getSpecialDeliveryModes = createObservableDataAction({
    id: 'get-special-delivery-modes',
    action: <IAction<ISpecialDeliveryModes>>getSpecialDeliveryModesAsync,
    input: createInput
});

export default getSpecialDeliveryModes;
