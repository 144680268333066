/**
* SpecialDeliveryModes entity interface.
*/
export interface ISpecialDeliveryModes {
    PickupDlvMode?: string;
    ExpressDlvMode?: string;
}

/**
   * SpecialDeliveryModes entity class.
   */
export class SpecialDeliveryModesExtensionClass implements ISpecialDeliveryModes {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public PickupDlvMode: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExpressDlvMode: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.PickupDlvMode = odataObject.PickupDlvMode;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExpressDlvMode = odataObject.ExpressDlvMode;

    }
}