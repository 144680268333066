import { AsyncResult, DataServiceQuery, IContext, IDataServiceRequest, IQueryResultSettings, callActionOrExecute } from "@msdyn365-commerce/retail-proxy";
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

export function createUpdateCountingListLinesInput(queryResultSettings: IQueryResultSettings, productListLines: Entities.ProductListLine[]): IDataServiceRequest {
    const query = new DataServiceQuery("VinhusetProductLists", "VinhusetProductList");
    return query.createDataServiceRequestForOperation('UpdateCountingListLines', true, EntityClasses.ProductListLineClass, 'true', { bypassCache: 'get', returnEntity: 'Entities.ProductListLine' }, { productListLines: productListLines });
}

export function updateCountingListLinesAsync(context: IContext, productListLines: Entities.ProductListLine[]): AsyncResult<Entities.ProductListLine[]> {
    const request = createUpdateCountingListLinesInput(
        context.queryResultSettings || {}, productListLines);
    return callActionOrExecute<Entities.ProductListLine[]>(request, context.callerContext);
}