import { IActionContext } from "@msdyn365-commerce/core";
import { callActionOrExecute, DataServiceQuery, IDataServiceRequest } from "@msdyn365-commerce/retail-proxy";
import { ShipCarrierAddressExtensionClass, IShipCarrierAddress } from "./entities/ship-carrier-address";

function shipCarrierAddressQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function getShipCarrierAddressInput(location: number): IDataServiceRequest {
    const query = shipCarrierAddressQuery();
    return query.createDataServiceRequestForOperation('GetShipCarrierAddress',
        false,
        ShipCarrierAddressExtensionClass,
        'false',
        { bypassCache: 'get', returnEntity: 'IShipCarrierAddress' },
        {
            location: location
        });
}

export async function getShipCarrierAddressAsync(context: IActionContext, location: number): Promise<IShipCarrierAddress | undefined> {
    const request = getShipCarrierAddressInput(location);
    const result = await callActionOrExecute<IShipCarrierAddress>(request, context);
    return result;
}
