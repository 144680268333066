import { IActionContext } from '@msdyn365-commerce/core';
import { generateProductImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import {
    callActionOrExecute,
    DataServiceQuery,
    IContext,
    IDataServiceRequest,
    IQueryResultSettings
} from '@msdyn365-commerce/retail-proxy';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Types from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

function getByIdsVinhusetQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function getByIdsVinhusetInput(
    queryResultSettings: IQueryResultSettings,
    productIds: number[],
    channelId: number,
    inventLocationId: string | null
): IDataServiceRequest {
    const query = getByIdsVinhusetQuery().resultSettings(queryResultSettings);
    const request = query.createDataServiceRequestForOperation(
        'GetByIdsVinhuset',
        true, Entities.SimpleProductClass,
        'true',
        { bypassCache: 'get', returnEntity: 'Entities.SimpleProductClass' },
        {
            productIds: productIds,
            channelId: channelId,
            inventLocationId: inventLocationId
        }
    );
    return request;
}

export async function getByIdsVinhusetAsync(context: IContext, productIds: number[], channelId: number, inventLocationId: string | null): Promise<Types.SimpleProduct[]> {
    const request = getByIdsVinhusetInput(context.queryResultSettings || {}, productIds, channelId, inventLocationId);
    const products = await callActionOrExecute<Types.SimpleProduct[]>(request, context.callerContext);

    // Prepend the base image url to each image
    products.forEach(product => {
        try {
            const newImageUrl = generateProductImageUrl(product, context.callerContext.requestContext.apiSettings as any);

            if (newImageUrl) {
                product.PrimaryImageUrl = newImageUrl;
            }
        } catch {
            (context.callerContext as IActionContext).trace('[getByIdsVinhusetAsync] Unable to update ImageURL for Product');
        }
    });

    return products;
}