import { IActionContext } from '@msdyn365-commerce/core';
import { ProductRefinerValue, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy';
import getOrganizationCustomer, { GetOrganizationCustomerInput } from '../actions/get-organization-customer';

export function shouldFilterAlcohol(context: IActionContext): boolean {
    const doAlcoholFiltering = context.requestContext.url.requestUrl.searchParams.get('alcohol')?.toLowerCase() !== 'false';
    const alcoholRefinerRecordId = Number(context.requestContext.app.config?.alcoholRefinerRecordId);

    return doAlcoholFiltering && !isNaN(alcoholRefinerRecordId) && alcoholRefinerRecordId > 0;
}

export async function getAlcoholRefiners(context: IActionContext): Promise<ProductRefinerValue[]> {
    const alcoholGroupAttributeId = Number(context.requestContext.app.config?.alcoholRefinerRecordId);

    if (isNaN(alcoholGroupAttributeId) || alcoholGroupAttributeId === 0) {
        return [];
    }

    const result: ProductRefinerValue[] = [];
    const alcoholRefiner: ProductRefinerValue = {
        DataTypeValue: 5,
        LeftValueBoundString: '0',
        RefinerRecordId: alcoholGroupAttributeId,
        RefinerSourceValue: 1,
        RightValueBoundString: '0',
        UnitText: ''
    };

    result.push(alcoholRefiner);

    try {
        let alcoholLicenseValue = 0;

        if (context.requestContext.user.isAuthenticated) {
            // Get Customer attributes
            // const customer = await getCustomer(new GetCustomerInput(context.requestContext.apiSettings, context.requestContext.user.customerAccountNumber), context);
            const customer = await getOrganizationCustomer(new GetOrganizationCustomerInput(context.requestContext.apiSettings, context.requestContext.user.customerAccountNumber!), context);
            const alcoholLicense = Number(customer.Attributes?.find(x => x.RecordId === alcoholGroupAttributeId)?.AttributeValue?.StringValue);

            if (isNaN(alcoholLicense)) {
                // Throw NaN error
                console.warn('Alcohol license value is missing or not a number.');
            } else {
                alcoholLicenseValue = alcoholLicense;
            }
        } else {
            // If user is not logged in show all products
            alcoholLicenseValue = 3;
        }

        // TODO: Verify we can do ranges like this on text attributes
        // If we can do range on text attributes use this:
        //alcoholRefiner.RightValueBoundString = alcoholLicense?.AttributeValue?.StringValue ?? '0';

        // Add a refiner for each alcohol group
        Array(alcoholLicenseValue).fill(0).forEach((_, index) => {
            result.push({
                ...alcoholRefiner,
                LeftValueBoundString: (index + 1).toString(),
                RightValueBoundString: (index + 1).toString()
            });
        });
    } catch (error) {
        console.warn('Failed to get customer attributes for alcohol license or parse value of attribute. No alcohol license applied to search.', error);
    }

    return result;
}

export async function addAlcoholRefiner(context: IActionContext, searchCriteriaInput: ProductSearchCriteria): Promise<void> {
    const alcoholGroupAttributeId = Number(context.requestContext.app.config?.alcoholRefinerRecordId);

    if (isNaN(alcoholGroupAttributeId) || alcoholGroupAttributeId === 0) {
        return;
    }

    if (searchCriteriaInput.Refinement!.some(x => x.RefinerRecordId === alcoholGroupAttributeId)) {
        // Remove existing, shouldn't be here
        searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.filter(x => x.RefinerRecordId !== alcoholGroupAttributeId);
    }

    const refiners = await getAlcoholRefiners(context);

    searchCriteriaInput.Refinement = searchCriteriaInput.Refinement!.concat(refiners);

    return Promise.resolve();
}
