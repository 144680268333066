import { createObservableDataAction, IAction, IActionContext } from '@msdyn365-commerce/core';
import {
    AsyncResult,
    CacheType,
    DataServiceQuery,
    IActionInput,
    IContext,
    IDataServiceRequest,
    callActionOrExecute
} from '@msdyn365-commerce/retail-proxy';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

export class GetCountingListInput implements IActionInput {
    public shouldCacheOutput = (): boolean => false;

    public getCacheKey = () => 'get-counting-list';
    public getCacheObjectType = () => 'counting-list';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (): GetCountingListInput => {
    return new GetCountingListInput();
};

export function createGetCountingListInput(productListId: string): IDataServiceRequest {
    const query = new DataServiceQuery('VinhusetProductLists', 'VinhusetProductList');
    return query.createDataServiceRequestForOperation(
        'GetCountingList',
        true,
        EntityClasses.ProductListClass,
        'false',
        { bypassCache: 'none', returnEntity: 'Entities.ProductList' },
        { productListId: productListId }
    );
}

export function getCountingListAsync(context: IContext, productListId: string): AsyncResult<Entities.ProductList> {
    const request = createGetCountingListInput(productListId);
    return callActionOrExecute<Entities.ProductList>(request, context.callerContext);
}

export async function getCountingListAsyncAction(input: GetCountingListInput, context: IActionContext): Promise<Entities.ProductList> {
    if (!context.requestContext.user.isAuthenticated) {
        throw new Error('Unable to get counting list. User is not authenticated.');
    }

    if (!context.requestContext.query?.id) {
        throw new Error('Failed to get counting list id from the query.');
    }

    const countingList = await getCountingListAsync({ callerContext: context }, context.requestContext.query.id);

    return countingList;
}

export const getCountingList = createObservableDataAction({
    id: 'vinhuset/get-counting-list',
    action: <IAction<Entities.ProductList>>getCountingListAsyncAction,
    input: createInput
});

export default getCountingList;
